import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";

export const cashVoucherPkWiseStatementSlice = createSlice({
    name: 'cashVoucherPkWiseStatement',
    initialState: {
        loader: false,
        serverResponseStatus: {
            apiCalled: false,
            message: '',
            hasError: false,
        },
        total_rows: 0,
        page: 1,
        count: countPerPage,
        pkWiseList: [],
        expense: { credit: 0, debit: 0 },
        filters: { count: countPerPage, page: 1, voucher_id: 0, from_date: 0, to_date: 0, transaction_type_id: 0, payment_mode_id: 0, branch_id: 0 },
        transaction_type_list: [],
        payment_mode_list: null,
        branch_list: [],
        cashID: 0,

    },
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setPkWiseList: (state, action) => {
            const { data, total_rows, expense } = action.payload;
            state.pkWiseList = data;
            state.total_rows = total_rows;
            state.expense = expense
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        pageChange: (state, action) => {
            state.filters = action.payload
        },
        setFilters: (state, action) => {
            state.filters = { ...action.payload }
        },
        transactionTypeList: (state, action) => {
            state.transaction_type_list = action.payload
        },
        paymentTypeList: (state, action) => {
            state.payment_mode_list = action.payload
        },
        branchList: (state, action) => {
            state.branch_list = action.payload
        },
        setCashId: (state, action) => {
            state.cashID = action.payload
        },

    }
})

export const { setLoader, apiResponse, setPkWiseList, setPage, paymentTypeList, transactionTypeList, setFilters, pageChange, branchList, setCashId } = cashVoucherPkWiseStatementSlice.actions
export default cashVoucherPkWiseStatementSlice.reducer