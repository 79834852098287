import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    employeeTypeList: [],
    leaveCategoryList: [],
    statuslist: [],
    earnList: [],
    confirmationModal: false,
    policyData: {}
}

export const createLeavePolicy = createSlice({
    name: 'createLeavepolicy',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },

        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setStatusList: (state, action) => {
            state.statuslist = action.payload;
        },
        setCategoryList: (state, action) => {
            state.leaveCategoryList = action.payload;
        },
        setEarnList: (state, action) => {
            state.earnList = action.payload;
        },
        setConfirmationModal: (state, action) => {
            state.confirmationModal = action.payload
        },
        setEmployeeTypeList: (state, action) => {
            state.employeeTypeList = action.payload;
        },
        setPolicyData: (state, action) => {
            state.policyData = action.payload;
        },
        resetState: () => initialState
    }
})

export const { setLoader, apiResponse, setCategoryList, setEmployeeTypeList, setStatusList, resetState, setEarnList, setConfirmationModal, setPolicyData } = createLeavePolicy.actions;
export default createLeavePolicy.reducer;