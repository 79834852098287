import CryptoJS from 'crypto-js';

const SALT = process.env.REACT_APP_AES_KEY


const encryptData = (data) => {
  // console.log(data, 'from auth')
  return CryptoJS.AES.encrypt(JSON.stringify(data), SALT).toString();
}

const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SALT);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export const getToken = () => {
  return localStorage.getItem('token') || null;
}

export const saveToken = (token) => {
  return localStorage.setItem('token', token);
}

export const getProfileImageUrl = () => {
  if (localStorage.getItem('profile_url'))
    return decryptData(localStorage.getItem('profile_url'));
  else
    return ""
}

export const removeToken = () => {
  localStorage.removeItem("token");
}

export const saveLoggedInUserDetails = (userData) => {
  localStorage.setItem('user', encryptData(userData));
}

export const saveCompanyDetails = (data) => {
  localStorage.setItem('company_details', encryptData(data));

}

export const saveProfileImageUrl = (url) => {
  localStorage.setItem('profile_url', encryptData(url));
}

export const saveVersion = (data) => {
  localStorage.setItem('version', data)
}

export const getVersion = () => {
  // if (localStorage.getItem('version'))
    return localStorage.getItem('version')
  // else
    // return null;
}

export const removeLoggedInUserDetails = () => {
  localStorage.removeItem('user');
}

export const getLoggedInUserDetails = () => {
  if (localStorage.getItem('user'))
    return decryptData(localStorage.getItem('user'));
}

export const getValidityToken = () => {
  return localStorage.getItem('validity_token') || null;
}

export const saveValidityToken = (validity_token) => {
  return localStorage.setItem('validity_token', validity_token);
}

export const getCompanyInfo = () => {
  const userDetails = getLoggedInUserDetails();
  return userDetails?.company;

}

export const getBranchInfo = () => {
  const userDetails = getLoggedInUserDetails();
  return userDetails?.branch;
}

export const getLevel = () => {
  const userDetails = getLoggedInUserDetails();
  return userDetails?.position_level.level;
}

export const getCompanyDetails = () => {
  if (localStorage.getItem('company_details'))
    return decryptData(localStorage.getItem('company_details'));
  else
    return ""
}

export const getProfilePic = () => {
  const userDetails = getLoggedInUserDetails();
  return userDetails;
}

export const getRoleInfo = () => {
  const userDetatils = getLoggedInUserDetails();
  return userDetatils.role[0];
}

// console.log(getLoggedInUserDetails(), 'from auth')
