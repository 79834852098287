import { createSlice } from "@reduxjs/toolkit";

export const fileLoaclExpenseSlice = createSlice({
    name:'fileLocalExpense',
    initialState:{
        loader: false,
        serverResponseStatus: {
            apiCalled: false,
            message: '',
            hasError: false,
        },
        category_list: [],
        expenseData: {},
        confirmationModal: false,
    },
    reducers:{
        setLoader:(state,action)=>{
            state.loader = action.payload;
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setCategoryList: (state, action) => {
            state.category_list = action.payload
        },
        setConfirmationModal: (state, action) => {
            state.confirmationModal = action.payload
        },
        setExpenseData: (state, action) => {
            state.expenseData = action.payload
        },
    }
})

export const {apiResponse,setCategoryList,setConfirmationModal,setExpenseData,setLoader} = fileLoaclExpenseSlice.actions
export default fileLoaclExpenseSlice.reducer
