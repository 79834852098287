import { createSlice } from '@reduxjs/toolkit'

export const profileDetailSlice = createSlice({
    name: 'profileDetail',
    initialState: {
        profileDetails: ""
    },
    reducers: {
        profileUpdate: (state, action) => {
            state.profileDetails = action.payload
        },
    },
})

export const { profileUpdate } = profileDetailSlice.actions

export default profileDetailSlice.reducer
