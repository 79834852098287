import { createSlice } from '@reduxjs/toolkit'

export const noticeboardSlice = createSlice({
  name: 'noticeboard',
  initialState: {
    noticeBoardType: [],
    noticeBoardPriority: [],
    noticeBoardBranches: [],
    noticeBoardGlobal: [{ label: "Yes", value: true }, { label: "No", value: false }],
    serverResponseStatus: { apiCalled: false, message: '', hasError: false }
  },
  reducers: {
    typeList: (state, action) => {
      state.noticeBoardType = action.payload
    },
    priorityList: (state, action) => {
      state.noticeBoardPriority = action.payload
    },
    branchList: (state, action) => {
      state.noticeBoardBranches = action.payload
    },
    apiResponse: (state, action) => {
      state.serverResponseStatus = { ...action.payload }
    }
  },
})

// Action creators are generated for each case reducer function
export const { typeList, priorityList, apiResponse, branchList } = noticeboardSlice.actions

export default noticeboardSlice.reducer