import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false
    },
    loader: true,
    leave_policy_list: [],
    weeklyoff_list: [],
    attendance_policy_list: [],
    holiday_policy_list: [],
    branchPolicyData: {}
}

export const editPolicyMaping = createSlice({
    name: "editPolciyMaping",
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = action.payload;
        },
        setLeavePolicyList: (state, action) => {
            state.leave_policy_list = action.payload;
        },
        setweeklyOffPolicyList: (state, action) => {
            state.weeklyoff_list = action.payload
        },
        setAttendancePolicyList: (state, action) => {
            state.attendance_policy_list = action.payload;
        },
        setHolidayPolicyList: (state, action) => {
            state.holiday_policy_list = action.payload;
        },
        setBranchPolicyData: (state, action) => {
            state.branchPolicyData = action.payload;
        },
        resetstate: () => initialState
    }
})

export const { setLoader, apiResponse, setLeavePolicyList, setAttendancePolicyList, setHolidayPolicyList, setweeklyOffPolicyList, setBranchPolicyData, resetstate } = editPolicyMaping.actions;
export default editPolicyMaping.reducer;