import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    daysList: [],
    weekList: [],
    statusList: [],
    confirmationModal: false,
    submitData: {}
}

export const createWeeklyPolicy = createSlice({
    name: "createWeelkyPolicy",
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setDaysList: (state, action) => {
            state.daysList = action.payload
        },
        setWeekList: (state, action) => {
            state.weekList = action.payload
        },
        setStatutsList: (state, action) => {
            state.statusList = action.payload
        },
        setSubmitData: (state, action) => {
            state.submitData = action.payload
        },
        setConfirmationModal: (state, action) => {
            state.confirmationModal = action.payload
        },
        resetState: () => initialState
    }
})

export const { setLoader, apiResponse, setDaysList, setWeekList, setStatutsList, setSubmitData, setConfirmationModal,resetState } = createWeeklyPolicy.actions;
export default createWeeklyPolicy.reducer;