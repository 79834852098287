import { createSlice } from "@reduxjs/toolkit";

export const fileAdvanceExpenseSlice = createSlice({
    name: 'fileAdvanceExpense',
    initialState: {
        loader:true,
        advanceExpenseId: 0,
        expenseData: {},
        serverResponseStatus: {
            apiCalled: false,
            message: '',
            hasError: false,
        },
        confirmationModal:false,
    },
    reducers:{
        setLoader: (state,action)=>{
            state.loader = action.payload;
        } ,
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setExpenseData: (state, action) => {
            state.expenseData = action.payload
        },
        setConfirmationModal: (state, action) => {
            state.confirmationModal = action.payload
        },
        setAdvanceExpenseID: (state, action) => {
            state.advanceExpenseId = action.payload
        },
    }
})

export const {apiResponse,setAdvanceExpenseID,setConfirmationModal,setExpenseData,setLoader} = fileAdvanceExpenseSlice.actions
export default fileAdvanceExpenseSlice.reducer;