import React, { Component, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './scss/style.scss';
import PrivateRoute from './components/PrivateRoute'
import PageLoader from './components/Loader'
import DisplayAlert from './components/DisplayAlert';
import { useState } from 'react';

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// )

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Birthday = React.lazy(() => import('src/views/pages/birthday/Birthday'))

// MaheshB start here

// login
const UserLogin = React.lazy(() => import(`./views/pages/login/Login`));

// const networkCheck = () => {

// setInterval(function () {
//   let networkFlag = navigator.onLine;
//   console.log(networkFlag);
// }, 3000);




const App = () => {
  const [serverResponseStatus, setServerResponseStatus] = useState({
    apiCalled: false,
    message: '',
    hasError: false
  });

  // window.addEventListener('offline', (event) => {

  //   console.log("The network connection has been lost.");
  //   setServerResponseStatus({ apiCalled: true, message: 'The network connection has been lost.', hasError: true })

  //   setTimeout(()=>{
  //     setServerResponseStatus({ apiCalled: false })
  //   },3000)

  // });


  // console.log(process.env.REACT_APP_API_URL);


  console.log('-----------------test-----------------')
  return (
    <BrowserRouter>
      <DisplayAlert serverResponseStatus={serverResponseStatus} />
      <React.Suspense fallback={<PageLoader />}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <UserLogin {...props} />} />
          {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          {/* <Route exact path="*" name="Page 404" render={props => <Page404 {...props} />} /> */}
          <Route exact path="/birthday" name="Happy Birthday" render={props => <Birthday {...props} />} />
          {/* <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}

          {/* <Route path="/" name="Dashboard" render={props => <TheLayout {...props}/>} /> */}
          <PrivateRoute path="/" name="Dashboard" component={TheLayout} />
          <Route component={Page404} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}


export default App;
