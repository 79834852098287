import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    leaveData: {},
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false
    },
    loader: false,
    confirmationModal: { show: false, statusId: 0, titlle: "" },
    approveId: 0 ,
    rejectId:0,
    cancelApproveId:0,
}

export const moduleAdminLeaveView = createSlice({
    name: 'moduleAdminLeaveView',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = action.payload;
        },
        setLeaveData: (state, action) => {
            state.leaveData = action.payload;
        },
        setConfirmationModal: (state, action) => {
            state.confirmationModal = {...action.payload};
        },
        closeConfirmationModal:(state,action)=>{
            state.confirmationModal = { show: false, statusId: 0, titlle: "" }
        },
        setStatusId: (state,action)=>{
            const {approveId,rejectId,cancelApproveId} = action.payload;
            state.approveId = approveId;
            state.rejectId = rejectId;
            state.cancelApproveId = cancelApproveId;
        },
        resetState: () => initialState,
    }
})

export const { setConfirmationModal, setLeaveData, setLoader, setStatusId,apiResponse,closeConfirmationModal,resetState } = moduleAdminLeaveView.actions;
export default moduleAdminLeaveView.reducer;