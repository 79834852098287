import { createSlice } from '@reduxjs/toolkit'
import { countPerPage } from 'src/config/app'
import { formatDate } from 'src/helpers/dateCastingHandler'

var initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    epic_task_list: {
        data: [],
        total: 0
    },
    task_list: {
        data: [],
        total: 0
    },
    epic_task_filter: {
        page: 1,
        count: countPerPage,
        assignee: [],
        status: [],
        priority: [],
        created_by: [],

    },
    task_filter: {
        page: 1,
        count: countPerPage,
        assignee: [],
        status: [],
        priority: [],
        created_by: [],
        title:''
    },
    subTaskModal: {
        show: false,
        data: undefined
    },
    priorityList: [],
    statusList: [],
    employeeList: [],
    epicTaskId: null,
    taskId: null,
    epicModal: false,
    minStartDate: formatDate(new Date(), 'YYYY-MM-DD'),
    minEndDate: formatDate(new Date(), 'YYYY-MM-DD'),
    maxStartDate:'',
    maxEndDate:'',
}

export const taskListSlice = createSlice({
    name: 'taskList',
    initialState,
    reducers: {
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setEpicTaskList: (state, action) => {
            const { data, total } = action.payload
            state.epic_task_list = { data, total }
        },
        setTaskList: (state, action) => {
            const { data, total } = action.payload
            state.task_list = { data, total }
        },
        setEpicTaskFilter: (state, action) => {
            state.epic_task_filter = action.payload
        },
        setTaskFilter: (state, action) => {
            state.task_filter = action.payload
        },
        setSubTaskModal: (state, action) => {
            state.subTaskModal = action.payload
        },
        setPriorityList: (state, action) => {
            state.priorityList = action.payload
        },
        setStatusList: (state, action) => {
            state.statusList = action.payload
        },
        setEmployeeList: (state, action) => {
            state.employeeList = action.payload
        },
        setEpicTaskId: (state, action) => {
            state.epicTaskId = action.payload
        },
        setTaskId: (state, action) => {
            state.taskId = action.payload
        },
        setMinStartDate: (state, action) => {
            state.minStartDate = action.payload
        } ,
        setMinEndDate: (state, action) => {
            state.minEndDate = action.payload
        } ,
        setMaxStartDate: (state, action) => {
            state.maxStartDate = action.payload
        } ,
        setMaxEndDate: (state, action) => {
            state.maxEndDate = action.payload
        } ,
        setEpicModal: (state, action) => {
            state.epicModal = action.payload
        },
        reset: () => initialState,
    }
})

export const { apiResponse, setLoader, setEpicTaskList, setTaskList, reset, setEpicTaskFilter, setTaskFilter, setSubTaskModal, setPriorityList, setTaskId, setEpicModal, setStatusList, setEmployeeList, setEpicTaskId, setMinStartDate, setMinEndDate, setMaxStartDate, setMaxEndDate } = taskListSlice.actions
export default taskListSlice.reducer