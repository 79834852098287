import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";

const initialState = {
    loader: true,
    count: {
        approved_expense: 0,
        settled_expense: 0,
    },
    managerApprovalCount: {
        expense: null,
        leave: null,
        regularisation: null,
        dwr: null,
        helpdesk: null
    },
    totalCount: 0,
    managerTotalCount: 0,
}

export const actionPendingSlice = createSlice({
    name: 'actionPending',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setApprovedExpense: (state, action) => {
            state.count.approved_expense = action.payload;
        },
        setSettledExpense: (state, action) => {
            state.count.settled_expense = action.payload;
        },
        setTotalCount: (state, action) => {
            state.totalCount = action.payload;
        },
        setManagerExpense: (state, action) => {
            state.managerApprovalCount.expense = action.payload;
        },
        setManagerLeave: (state, action) => {
            state.managerApprovalCount.leave = action.payload;
        },
        setManagerRegularisation: (state, action) => {
            state.managerApprovalCount.regularisation = action.payload;
        },
        setManagerDwr: (state, action) => {
            state.managerApprovalCount.dwr = action.payload;
        },
        setManagerHelpdesk: (state, action) => {
            state.managerApprovalCount.helpdesk = action.payload;
        },

        setManagerTotalCount: (state, action) => {
            state.managerTotalCount = action.payload;
        }
    }
})

export const { setLoader, setApprovedExpense, setSettledExpense, setTotalCount,setManagerTotalCount, setManagerExpense, setManagerLeave, setManagerRegularisation, setManagerDwr, setManagerHelpdesk } = actionPendingSlice.actions;
export default actionPendingSlice.reducer