import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    holidayMasterList: [],
    holidayPolicyData: {},
    showAddHolidayModal: false,
    policyStatusList: [],
}

export const editHolidayPolicy = createSlice({
    name: "editHolidayPolicy",
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setHolidayMasterList: (state, action) => {
            state.holidayMasterList = action.payload;
        },
        setHolidayPolicyData: (state, action) => {
            state.holidayPolicyData = action.payload;
        },
        setShowAddHolidayModal: (state, action) => {
            state.showAddHolidayModal = action.payload;
        },
        setPolicyStatusList: (state, action) => {
            state.policyStatusList = action.payload;
        },
    }
})

export const { setLoader, apiResponse, setHolidayMasterList, setHolidayPolicyData, setShowAddHolidayModal, setPolicyStatusList } = editHolidayPolicy.actions;
export default editHolidayPolicy.reducer