import { createSlice } from '@reduxjs/toolkit'

export const sidebarToggleSlice = createSlice({
  name: 'sidebarToggle',
  initialState: {
    sidebarShow: 'responsive'
  },
  reducers: {
    set: (state, {payload}) => {
        state.sidebarShow = payload.sidebarShow
    }
  },
})

// Action creators are generated for each case reducer function
export const { set } = sidebarToggleSlice.actions

export default sidebarToggleSlice.reducer