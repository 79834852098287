import CIcon from '@coreui/icons-react'

export const _superadminNav = [
    {
        _tag: 'CSidebarNavItem',
        name: 'Dashboard',
        to: `/dashboard`,
        icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
        exact: false,
        // badge: {
        //   color: 'info',
        //   text: 'NEW',
        // }
    },
    // {
    //     _tag: 'CSidebarNavTitle',
    //     _children: ['Components']
    //   },
    // {
    //   _tag: 'CSidebarNavDropdown',
    //   name: 'Employee Section',
    //   route: '/base',
    //   icon: 'cil-user',
    //   _children: [
    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: 'Employee Directory',
    //       to: '/employees/directory',
    //     },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'Breadcrumb',
    //   to: '/base/breadcrumbs',
    // },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'Cards',
    //   to: '/base/cards',
    // },
    //   ],
    // },
    // {
    //   _tag: 'CSidebarNavDropdown',
    //   name: 'Team Section',
    //   route: '/base',
    //   icon: 'cil-people',
    //   _children: [],
    // },
    {
        _tag: 'CSidebarNavDropdown',
        name: 'Admin Section',
        route: '/base',
        icon: 'cil-people',
        _children: [
            {
                _tag: 'CSidebarNavDropdown',
                icon: <CIcon name='cil-building' className='mr-2' />,
                name: 'Company Setup',
                route: '/base',
                // icon: 'cil-people',
                _children: [
                    {
                        _tag: 'CSidebarNavItem',
                        icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                        name: 'Company Profile',
                        to: '/company',
                        exact: false,
                    },
                    {
                        _tag: 'CSidebarNavItem',
                        icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                        name: 'Manage Branches',
                        to: '/branch',
                        exact: false,
                    },
                    {
                        _tag: 'CSidebarNavItem',
                        icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                        name: 'Manage Departments',
                        to: '/department',
                        exact: false,
                    },
                    // {
                    //     _tag: 'CSidebarNavItem',
                    //     name: 'Organization',
                    //     to: '/',
                    // },
                ],
            },
            // {
            //     _tag: 'CSidebarNavDropdown',
            //     name: 'Daily Work Report',
            //     route: '/dwr',
            //     // icon: 'cil-people',
            //     _children: [
            //         // {
            //         //   _tag: 'CSidebarNavItem',
            //         //   name: 'Companys',
            //         //   to: '/base/tooltips',
            //         // },
            //     ],
            // },
            // {
            //   _tag: 'CSidebarNavItem',
            //   name: 'Core HR',
            //   to: `/core-hr`,
            //   icon: <CIcon name="cil-list" customClasses="c-sidebar-nav-icon" />
            // },
            {
                _tag: 'CSidebarNavDropdown',
                icon: <CIcon name='cil-building' className='mr-2' />,
                name: 'Core HR',
                route: '/core-hr',
                // icon: 'cil-people',
                _children: [
                    {
                        _tag: 'CSidebarNavItem',
                        icon: <CIcon name='cil-user-plus' className="ml-3 mr-2" />,
                        name: 'Onboarding',
                        to: '/employee/add',
                        exact: false,
                    },
                    {
                        _tag: 'CSidebarNavItem',
                        icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                        name: 'Manage Employees',
                        to: '/employee',
                        exact: false,
                    },
                    {
                        _tag: 'CSidebarNavItem',
                        icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                        // name: 'Document & Template',
                        name: 'HR Template',
                        to: '/document/template',
                        exact: false,
                    },
                ],
            },
            {
                _tag: 'CSidebarNavDropdown',
                name: 'Cash Voucher',
                route: '/cash-voucher',
                code: 'vouchers',
                icon: <CIcon name='cil-building' className='mr-2' />,
                _children: [
                    {
                        _tag: 'CSidebarNavItem',
                        name: 'Dashboard',
                        icon: <CIcon name='cil-user-plus' className="ml-3 mr-2" />,
                        to: '/cash-voucher',
                        exact: false,
                    },
                    {
                        _tag: 'CSidebarNavItem',
                        icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                        name: 'Statements',
                        to: '/cash-voucher/statement',
                        exact: false,
                    },
                ],
            },
            {
                _tag: 'CSidebarNavItem',
                icon: <CIcon name='cil-clipboard' className='mr-2' />,
                name: 'Assets',
                to: '/assets',
                code: 'assets',
                exact: false,
            },
            // {
            //     _tag: 'CSidebarNavDropdown',
            //     name: 'Help Desk',
            //     route: '/base',
            //     // icon: 'cil-people',
            //     _children: [
            //         // {
            //         //   _tag: 'CSidebarNavItem',
            //         //   name: 'Companys',
            //         //   to: '/base/tooltips',
            //         // },
            //     ],
            // },
            {
                _tag: 'CSidebarNavItem',
                name: 'Manage Module Admins',
                to: `/manage-module`,
                icon: <CIcon name="cil-speedometer" className="mr-2" />,
                exact: false,
            },
            // {
            //   _tag: 'CSidebarNavItem',
            //   name: 'Core HR',
            //   to: '/base/tooltips',
            // },
            // {
            //   _tag: 'CSidebarNavItem',
            //   name: 'Help Desk',
            //   to: '/base/tooltips',
            // },
            // {
            //   _tag: 'CSidebarNavItem',
            //   name: 'Manage Modules',
            //   to: '/base/tooltips',
            // },
            // {
            //   _tag: 'CSidebarNavItem',
            //   name: 'Daily Work Report',
            //   to: '/base/tooltips',
            // },

        ],
    },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'Company',
    //   to: `/company`,
    //   icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />
    // },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'Branch',
    //   to: `/branch`,
    //   icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />
    // },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'Department',
    //   to: `/department`,
    //   icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />
    // },

    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'Employee',
    //   to: `/employee`,
    //   icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />
    // },
]
