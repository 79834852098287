import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    attendancePolicyList: [],
    confirmationModal: { id: 0, show: false }

}

export const attendancePolicyList = createSlice({
    name: 'attendancePolicyList',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setAttendancePolciyList: (state, action) => {
            state.attendancePolicyList = action.payload;
        },
        setConfirmationModal: (state, action) => {
            state.confirmationModal = action.payload;
        },
        resetState: () => initialState
    }
})

export const { setAttendancePolciyList, apiResponse, setLoader, setConfirmationModal, resetState } = attendancePolicyList.actions;
export default attendancePolicyList.reducer;