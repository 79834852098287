import CIcon from '@coreui/icons-react'
import { WorkReportIcon, EmployeeDirectoryIcon, HelpDeskIcon, MyProfileIcon, TaskIcon } from '../../assets/svg_icons/index';

export const _employeeNav = {
    normalemployeeNav: [
        {
            _tag: 'CSidebarNavItem',
            name: 'Dashboard',
            to: `/dashboard`,
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
            exact: false,
        },
        {
            _tag: 'CSidebarNavDropdown',
            name: 'Employee Section',
            route: '/base',
            icon: 'cil-user',
            _children: [
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-calendar' className='mr-2' />,
                    name: 'Attendance',
                    to: '/attendance-dashboard',
                    code: "attendance",
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <MyProfileIcon height="20" width="18" className='mr-2' />,
                    name: 'My Profile',
                    to: '/my-profile',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <HelpDeskIcon height="20" width="18" className='mr-2' />,
                    name: 'Helpdesk',
                    to: '/helpdesk',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <WorkReportIcon height="20" width="18" className='mr-2' />,
                    name: 'Daily Work Report',
                    to: '/daily-work-report',
                    exact: false,
                },
                // {
                //     _tag: 'CSidebarNavItem',
                //     name: 'Leaves',
                //     to: '/base/tooltips',
                // },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-clipboard' className='mr-2' />,
                    name: 'My Assets',
                    to: '/my-assets',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-cash' className='mr-2' />,
                    name: 'Expense',
                    to: '/expense',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-calendar' className='mr-2' />,
                    name: 'Leaves',
                    to: '/leaves',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    // icon: 'cil-address-book',
                    icon: <TaskIcon height="20" width="18" className='mr-2' />,
                    name: 'Tasks',
                    to: '/tasks',
                    exact: false,
                },

                // {
                //     _tag: 'CSidebarNavItem',
                //     name: 'Attendance',
                //     to: '/base/tooltips',
                // },
                {
                    _tag: 'CSidebarNavItem',
                    // icon: 'cil-address-book',
                    icon: <EmployeeDirectoryIcon height="20" width="18" className='mr-2' />,
                    name: 'Employee Directory',
                    to: '/employees/directory',
                    exact: false,
                },


            ],
        },
    ],
    _managerNav: [
        {
            _tag: 'CSidebarNavDropdown',
            name: 'Team Section',
            route: '/base',
            icon: 'cil-people',
            _children: [
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Daily Work Report',
                    icon: <CIcon name='cil-clipboard' className='mr-2' />,
                    to: '/dwr',
                    code: 'IT',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Expense',
                    icon: <CIcon name='cil-cash' className='mr-2' />,
                    to: '/expense-manager',
                    code: 'EX',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-cash' className='mr-2' />,
                    name: 'Leaves',
                    to: '/leaves-module',
                    code: "leave",
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-calendar' className='mr-2' />,
                    name: 'Regularisation',
                    to: '/regularisation-module',
                    code: "regularisation",
                    exact: false,
                },
            ],
        },
    ],
    _moduleadminNav: [
        {
            _tag: 'CSidebarNavDropdown',
            name: 'Module Manager',
            route: '/base',
            icon: 'cil-settings',
            _children: [
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Help Desk',
                    to: '/helpdesk-admin',
                    icon: <CIcon name='cil-clipboard' className='mr-2' />,
                    code: 'help_desk',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavDropdown',
                    name: 'Cash Voucher',
                    route: '/cash-voucher',
                    code: 'vouchers',
                    icon: <CIcon name='cil-building' className='mr-2' />,
                    _children: [
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Dashboard',
                            icon: <CIcon name='cil-user-plus' className="ml-3 mr-2" />,
                            to: '/cash-voucher',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                            name: 'Statements',
                            to: '/cash-voucher/statement',
                            exact: false,
                        },
                    ],
                },
            ],
        },
    ],
}