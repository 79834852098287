import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    weeklyData: {},
    policyList: [],
    daysList: [],
    weekList: [],
}

export const viewWeeklyPolicyList = createSlice({
    name: "viewWeeklyPolicyList",
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setWeeklyData: (state, action) => {
            state.weeklyData = action.payload
        },
        setPolicyList: (state, action) => {
            state.policyList = action.payload
        },
        setDaysList: (state, action) => {
            state.daysList = action.payload
        },
        setWeekList: (state, action) => {
            state.weekList = action.payload
        },
    }
})

export const { setLoader, apiResponse, setPolicyList, setWeeklyData,setDaysList,setWeekList } = viewWeeklyPolicyList.actions
export default viewWeeklyPolicyList.reducer