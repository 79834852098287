import {
  CImg,
  CSpinner
} from '@coreui/react'
import loader from './../assets/img/loader.gif';



function PageLoader() {

  return (
    // <div className="loader-container">
    //   <CSpinner className="page-loader" color="primary"/>
    //   <div className="modal-backdrop show remove-index"></div>
    // </div>

    <div className="loader-container">
      {/* <CSpinner className="page-loader" color="primary"/> */}
      <CImg src={loader} height='100px' />
      <div className="modal-backdrop show remove-index"></div>
    </div>
  )
}

export default PageLoader