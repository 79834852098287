import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getToken, getLoggedInUserDetails } from '../utils/auth'
import UserContext from './../context/User'

function PrivateRoute({component: Component, ...props}) {
  const token = getToken()
  //console.log(token, Component, props);
  const loggedInUserDetails = getLoggedInUserDetails()

  return (
    <Route
      {...props}
      render={({ location }) => token == null ? (
        <Redirect
          to='/login'
        />
      ) : (
        <UserContext.Provider value={loggedInUserDetails}>
          <Component {...props} />
        </UserContext.Provider>  
        )}
    />
  );
}

export default PrivateRoute
