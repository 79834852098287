import { createSlice } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";

export const fileExpenseStandardSlice = createSlice({
  name: "fileExpenseStandard",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  initialState: {
    loader: true,
    serverResponseStatus: {
      apiCalled: false,
      message: "",
      hasError: false,
    },
    category_list: [],
    type_list: [],
    confirmationModal: false,
    expenseData: {},
    pendingStatusId: 0,
    draftStatusId: 0,
    minDate: 0,
    maxDate: 0,
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    apiResponse: (state, action) => {
      state.serverResponseStatus = { ...action.payload };
    },
    setCategoryList: (state, action) => {
      state.category_list = action.payload;
    },
    setTypeList: (state, action) => {
      state.type_list = action.payload;
    },
    setConfirmationModal: (state, action) => {
      state.confirmationModal = action.payload;
    },
    setExpenseData: (state, action) => {
      state.expenseData = action.payload;
    },
    setPendinStatusId: (state, action) => {
      state.pendingStatusId = action.payload;
    },
    setDraftStatusId: (state, action) => {
      state.draftStatusId = action.payload;
    },

    setMinDate: (state, action) => {
      state.minDate = action.payload;
    },
    setMaxDate: (state, action) => {
      state.maxDate = action.payload;
    },
  },
});
export const {
  setCategoryList,
  setTypeList,
  apiResponse,
  setMinDate,
  setMaxDate,
  setConfirmationModal,
  setLoader,
  setExpenseData,
  setPendinStatusId,
  setDraftStatusId,
} = fileExpenseStandardSlice.actions;
export default fileExpenseStandardSlice.reducer;
