import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    status_list: [],
    hours_list: [],
    minutes_list: [],
    confirmationModal: false
}

export const attendnacePolicyCreate = createSlice({
    name: 'attendancePolicyCreate',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setStatusList: (state, action) => {
            state.status_list = action.payload;
        },
        setHoursList: (state, action) => {
            state.hours_list = action.payload;
        },
        setMinutesList: (state, action) => {
            state.minutes_list = action.payload;
        },
        setConfirmationModal: (state, action) => {
            state.confirmationModal = action.payload;
        },
        resetState: () => initialState
    }
})

export const { setLoader, apiResponse, setStatusList, setHoursList, setConfirmationModal, setMinutesList, resetState } = attendnacePolicyCreate.actions;
export default attendnacePolicyCreate.reducer;