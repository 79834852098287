import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";

const initialState = {
    loader: true,
    roleInfo: '',
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    // function statusId(state = roleInfo, action),

    filters: { page: 1, count: countPerPage, expense_id: "", from_date: 0, to_date: 0, category_id: 0, status_id: 1, user_id: 0, branch_id: 0 }, //status_id=1 for pending expense
    expenseListData: [],
    total_rows: 0,
    category_list: [],
    status_list: [],
    employee_list: [],
    tab: 'pending',
    branch_list: [],
    walletFilter: { count: countPerPage, page: 1, user_id: 0, from_date: 0, to_date: 0 },
    walletData: { total_rows: 0, data: [] },
    currentWalletData: { total_rows: 0, data: [] },
    currentWalletFilter: { count: countPerPage, page: 1, user_id: 0, branch_id: 0, department_id: 0 },
    department_List: [],
}

export const expenseListSlice = createSlice({
    name: 'expenseList',
    initialState,
    reducers: {
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setRoleInfo: (state, action) => {
            state.roleInfo = action.payload
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setFilters: (state, action) => {
            state.filters = action.payload
        },
        setExpenseListData: (state, action) => {
            const { data, total } = action.payload
            state.expenseListData = data;
            // roleInfo ? '' : ''
            // if (state.roleInfo[0] == 'hr')
            state.total_rows = total;
            // console.log(total, 'total_rows');
        },
        setPage: (state, action) => {
            state.filters = action.payload
        },
        setCategoryList: (state, action) => {
            state.category_list = action.payload
        },
        setStatusList: (state, action) => {
            state.status_list = action.payload
        },
        setEmployeeList: (state, action) => {
            state.employee_list = action.payload
        },
        setTab: (state, action) => {
            state.tab = action.payload
        },
        setBranchList: (state, action) => {
            state.branch_list = action.payload
        },
        setWalletData: (state, action) => {
            state.walletData = { ...action.payload }
        },
        setWalletFilter: (state, action) => {
            state.walletFilter = { ...action.payload }
        },
        setCurrentWalletBalance: (state, action) => {
            state.currentWalletData = { ...action.payload }
        },
        setCurrentWalletFilter: (state, action) => {
            state.currentWalletFilter = action.payload
        },
        setDepartmentList: (state, action) => {
            state.department_List = action.payload
        },
        reset: () => initialState,
    }
})

export const { setDepartmentList, setExpenseListData, setFilters, setLoader, setCategoryList, setStatusList, setCurrentWalletBalance, setCurrentWalletFilter,
    setMetricsData, setWalletBalance, setWalletData, apiResponse, setPage, reset, setEmployeeList, setWalletFilter, setTab, setBranchList, setRoleInfo } = expenseListSlice.actions
export default expenseListSlice.reducer