import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    organizationTrailsArray: []
}

export const employeeTrails = createSlice({
    name: 'employee trails',
    initialState,
    reducers: {
        setOrganizationaltrails: (state, action) => {
            state.organizationTrailsArray = [...action.payload]
        }
    }
})

export const { setOrganizationaltrails } = employeeTrails.actions;
export default employeeTrails.reducer