
import moment from 'moment';


export const formatDate = (dateToFormat, toFormat = 'DD/MM/YYYY', fromFormat = 'DD/MM/YYYY') => {
    //console.log(dateToFormat, toFormat, fromFormat);
    return moment(dateToFormat, fromFormat).format(toFormat);
}

export const todaysDate = () => moment();

export const subtractDays = (date, daysToSubtract) => {
    return date.subtract(daysToSubtract, 'days');
}

export const startDateOfMonth = (date) => date.startOf('month');

export const endDateOfMonth = (date) => date.endOf('month');

export const standardDateFormat = (date) => {
    return formatDate(date, 'DD MMM YYYY', 'DD/MM/YYYY')
}

export const standardDateTimeFormat = (date) => {
    return formatDate(date, 'DD MMM YYYY hh:mm A', 'DD/MM/YYYY hh:mm A')
}

export const currentFiscalYear = () => {
    var fiscalyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
        fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
        fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    return fiscalyear
}


const getDaysBetweenDates = function (startDate, endDate) {    // to get between dates in arr
    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('DD/MM/YYYY'));
        now.add(1, 'days');
    }
    return dates;
};

export function minutes_to_hhmm(numberOfMinutes) {
    //create duration object from moment.duration  
    var duration = moment.duration(numberOfMinutes, 'minutes');

    //calculate hours
    var hh = (duration.years() * (365 * 24)) + (duration.months() * (30 * 24)) + (duration.days() * 24) + (duration.hours());

    //get minutes
    var mm = duration.minutes();
    mm = mm.length == 1 ? "0" + mm : mm

    //return total time in hh:mm format
    return hh + ':' + mm;
}

export const getWorkingHours = hours => {
    var n = new Date(0, 0);
    n.setSeconds(+hours * 60 * 60);
    var h = n.getHours();
    var m = n.getMinutes();
    return `${h}hrs ${m}mins`;
}