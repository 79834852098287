import { createSlice } from "@reduxjs/toolkit";

export const viewExpenseSlice = createSlice({
    name: 'viewExpense',
    initialState: {
        loader: true,
        serverResponseStatus: {
            apiCalled: false,
            message: '',
            hasError: false,
        },
        expenseData:{},
        showModal:{visible:false,statusId:""},
        statusList:[],
        showAddBalanceModal:false,
    },
    reducers:{
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload };
        },
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
        setExpenseData: (state,action)=>{
            state.expenseData = action.payload;
        },
        setShowModal: (state,action) =>{
            state.showModal = action.payload;
        },
        setStatusList:(state,action) =>{
            state.statusList = action.payload
        },
        setShowAddBalanceModal: (state,action)=>{
            state.showAddBalanceModal = action.payload
        }
    }
})

export const {setShowModal,apiResponse,setExpenseData,setLoader,setStatusList,setShowAddBalanceModal} = viewExpenseSlice.actions
export default viewExpenseSlice.reducer