import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";

const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    leaveApplicationlist: { total_rows: 0, data: [] },
    currentTab: "pending",
    category_list: [],
    status_list: [],
    branch_list: [],
    type_list: [],
    employee_list: [],
    filter: { page: 1, count: countPerPage, leave_no: 0, from_date: 0, to_date: 0, leave_category_id: 0, leave_status_id: 0, user_id: 0, branch_id: 0 },
    proxyLeaveModal: false,
    addCompOffModal: false,
    branchID: 0,
    employee_list_by_branch: [],
    compoff_filter: { page: 1, count: countPerPage, from_date: 0, to_date: 0, user_id: 0, branch_id: 0, comp_off_status_id: 0 },
    compoff_list: { total_rows: 0, data: [] },
    compoff_status_list: [],
    firstHalfId: 0,
    secondHalfId: 0,
    managerStatusList: [],
    compoffPendingStatus: null,
    compoffActiveStatus: null,
    cancelModal: {
        show: false,
        id: null
    }
}

export const moduleAdminLeaveDashboard = createSlice({
    name: 'moduleAdminLeaveDashboard',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },

        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setLeaveApplicationList: (state, action) => {
            state.leaveApplicationlist = action.payload
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        setFilters: (state, action) => {
            state.filter = { ...action.payload }
        },
        setCategoryList: (state, action) => {
            state.category_list = action.payload;
        },
        setStatusList: (state, action) => {
            state.status_list = action.payload;
        },
        setTypeList: (state, action) => {
            state.type_list = action.payload;
        },
        setBranchList: (state, action) => {
            state.branch_list = action.payload
        },
        setEmployeeList: (state, action) => {
            state.employee_list = action.payload;
        },
        setProxyLeaveModal: (state, action) => {
            state.proxyLeaveModal = action.payload;
        },
        setCompOffModal: (state, action) => {
            state.addCompOffModal = action.payload;
        },
        setEmployeeListByBranch: (state, action) => {
            state.employee_list_by_branch = action.payload;
        },
        setBranchId: (state, action) => {
            state.branchID = action.payload;
        },
        setCompoffStatusList: (state, action) => {
            state.compoff_status_list = action.payload;
        },
        setCompoffFilters: (state, action) => {
            state.compoff_filter = { ...action.payload }
        },
        setCompOffList: (state, action) => {
            state.compoff_list = action.payload;
        },
        setHalfTypeId: (state, action) => {
            const { firstHalf, secondHalf } = action.payload
            state.firstHalfId = firstHalf;
            state.secondHalfId = secondHalf;
        },
        setCompoffPendingStatus: (state, action) => {
            state.compoffPendingStatus = action.payload;
        },
        setCompoffApprovedStatus: (state, action) => {
            state.compoffApproved = action.payload;
        },
        setManagerStatusList: (state, action) => {
            state.managerStatusList = action.payload
        },
        setCancelModal: (state, action) => {
            state.cancelModal = { ...action.payload }
        },
        resetCompoffFilters: (state, action) => {
            state.compoff_filter = { page: 1, count: countPerPage, leave_no: 0, from_date: 0, to_date: 0, user_id: 0, branch_id: 0, comp_off_status_id: 0 }
        },
        resetFilters: (state, action) => {
            state.filter = { page: 1, count: countPerPage, leave_no: 0, from_date: 0, to_date: 0, leave_category_id: 0, leave_status_id: 0, user_id: 0, branch_id: 0 }
        },
        resetState: () => initialState,
    }
})

export const { setLoader, apiResponse, resetState, setCurrentTab, resetFilters, setBranchList, setCompoffFilters, setCompoffApprovedStatus, setCompoffPendingStatus, setManagerStatusList, resetCompoffFilters, setHalfTypeId, setCompOffList, setCompoffStatusList, setLeaveApplicationList, setProxyLeaveModal, setBranchId, setEmployeeListByBranch, setFilters, setCategoryList, setEmployeeList, setStatusList, setTypeList, setCompOffModal, setCancelModal } = moduleAdminLeaveDashboard.actions;
export default moduleAdminLeaveDashboard.reducer