import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,

    },
    policyStatusList: [],
    holidayMasterList: [],
    holidayCalendarList: [],
    showAddHolidayMasterModal: false,
    showAddHolidayCalendarModal: false,
}

export const holidayPolicy = createSlice({
    name: 'holidayPolicy',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setPolicyStatusList: (state, action) => {
            state.policyStatusList = action.payload;
        },
        setHolidayMasterList: (state, action) => {
            state.holidayMasterList = action.payload;
        },
        setHolidayCalendarList: (state, action) => {
            state.holidayCalendarList = action.payload;
        },
        setShowAddHolidayMasterModal: (state, action) => {
            state.showAddHolidayMasterModal = action.payload;
        },
        setShowAddHolidayCalendarModal: (state, action) => {
            state.showAddHolidayCalendarModal = action.payload;
        },

    }
})

export const { setLoader, apiResponse, setPolicyStatusList, setHolidayCalendarList, setHolidayMasterList, setShowAddHolidayCalendarModal, setShowAddHolidayMasterModal } = holidayPolicy.actions;
export default holidayPolicy.reducer;