import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";

const initialState = {
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false
    },
    loader: false,
    currentTab: "attendance-calendar",
    regularisationDataList: { total: 0, data: [] },
    regularisation_status_list: [],
    regularisationFilters: { count: countPerPage, page: 1, status: 0 },
    attendanceFilter: { count: countPerPage, page: 1 },
    attendanceLogList: { total: 0, data: [] },
}

export const attendanceDashboardEmployee = createSlice({
    name: 'attendanceDashboardEmployee',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = action.payload;
        },
        setRegularisationList: (state, action) => {
            state.regularisationDataList = action.payload;
        },
        setRegularisationStatuslist: (state, action) => {
            state.regularisation_status_list = action.payload
        },
        setRegularisationFilters: (state, action) => {
            state.regularisationFilters = action.payload;
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        setAttendanceFilter: (state, action) => {
            state.attendanceFilter = action.payload;
        },
        setAttendanceLogList: (state, action) => {
            state.attendanceLogList = action.payload;
        },
    }
})

export const { setLoader, setRegularisationFilters, setRegularisationList, setAttendanceFilter, setAttendanceLogList, setRegularisationStatuslist, setCurrentTab, apiResponse } = attendanceDashboardEmployee.actions;
export default attendanceDashboardEmployee.reducer;