import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";
import { reset } from "../expense/dashboardSlice";

export const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    currentTab: 'leaves-calendar',
    showApplyModal: false,
    category_list: [],
    status_list: [],
    type_list: [],
    filters: { page: 1, count: countPerPage, leave_no: "", from_date: 0, to_date: 0, leave_category_id: 0, leave_status_id: 0 },
    leavesStatusCounter: {
        pending: 0,
        rejected: 0,
        approved: 0,
        financial_year: 0,
        cancelRequested: 0
    },
    leaveApplicationList: { total_rows: 0, data: [] },
    leaveCalendarEventlist: [],
    eventId: 0,
    eventInfoModal: false,
    eventModalData: {},
    showCancelModal: false,
    firstHalfId: 0,
    secondHalfId: 0,
    sl_count: { used: 0, remaining: 0 },
    pl_count: { used: 0, remaining: 0 },
    compoff_count: { used: 0, remaining: 0 },
    total_count: { used: 0, total: 0 },
    special_count: { paid: 0, unpaid: 0 },
    work_from_home: { used: 0, remaining: 0 },
    calendarDate: { start: "", end: "" },
    fiscal_year_list: [],
    current_fiscal_year: undefined,
    leave_earning_list: { total: 0, data: [] },
    leave_earning_filter: { page: 1, count: countPerPage }
}

export const employeeLeavesDashboard = createSlice({
    name: 'employeeLeavesDashboard',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },

        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setCurrentTab: (state, action) => {
            // console.log(action.payload, '------------action')
            state.currentTab = action.payload
        },
        setApplyModal: (state, action) => {
            state.showApplyModal = action.payload
        },
        setCategoryList: (state, action) => {
            state.category_list = action.payload;
        },
        setStatusList: (state, action) => {
            state.status_list = action.payload;
        },
        setTypeList: (state, action) => {
            state.type_list = action.payload;
        },
        setLeavesStatusCounter: (state, action) => {
            state.leavesStatusCounter = { ...action.payload }
        },
        setLeaveApplicationList: (state, action) => {
            state.leaveApplicationList = { ...action.payload }
        },
        setFilters: (state, action) => {
            state.filters = { ...action.payload };
        },
        setLeaveCalendarEventList: (state, action) => {
            state.leaveCalendarEventlist = action.payload;
        },
        setEventId: (state, action) => {
            state.eventId = action.payload;
        },
        setEventInfoModal: (state, action) => {
            state.eventInfoModal = action.payload;
        },
        setEventModalData: (state, action) => {
            state.eventModalData = action.payload;
        },
        setHalfTypeId: (state, action) => {
            const { firstHalf, secondHalf } = action.payload
            state.firstHalfId = firstHalf;
            state.secondHalfId = secondHalf;
        },
        setShowCancelModal: (state, action) => {
            state.showCancelModal = action.payload;
        },
        setBalanceLeaveCount: (state, action) => {
            const { sl_count, pl_count, compoff_count, special_count, work_from_home, total_count } = action.payload;
            state.sl_count = sl_count;
            state.pl_count = pl_count;
            state.special_count = special_count;
            state.compoff_count = compoff_count;
            state.work_from_home = work_from_home;
            state.total_count = total_count;
        },
        setLeaveEarnFilter: (state, action) => {
            state.leave_earning_filter = { ...action.payload };
        },
        setLeaveEarnLog: (state, action) => {
            state.leave_earning_list = action.payload;
        },
        setFiscalYearList: (state, action) => {
            state.fiscal_year_list = action.payload;
        },
        setCurrentFinancialYear: (state, action) => {
            state.current_fiscal_year = action.payload;
        },
        setCalendarDate: (state, action) => {
            state.calendarDate = action.payload;
        },
        resetState: () => initialState,
    }
})

export const { setLoader, apiResponse, resetState, setShowCancelModal, setCurrentTab, setFiscalYearList, setSlCount, setLeaveEarnFilter, setLeaveEarnLog, setCurrentFinancialYear, setPlCount, setBalanceLeaveCount, setEventInfoModal, setEventModalData, setCalendarDate, setApplyModal, setEventId, setCategoryList, setHalfTypeId, setLeaveCalendarEventList, setStatusList, setTypeList, setFilters, setLeavesStatusCounter, setLeaveApplicationList } = employeeLeavesDashboard.actions;
export default employeeLeavesDashboard.reducer