import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";


export const cashVoucherStatementSlice = createSlice({
    name: 'cashVoucherStatement',
    initialState: {
        loader: false,
        serverResponseStatus: {
            apiCalled: false,
            message: '',
            hasError: false,
        },
        total_rows: 0,
        page: 1,
        branch_id: 0,
        branch_list: [],
        statementList: [],




    },

    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setStatementList: (state, action) => {
            const { data, total_rows } = action.payload
            state.statementList = data;
            state.total_rows = total_rows;
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setBranchID: (state, action) => {
            state.branch_id = action.payload;
        },
        setBranchList: (state, action) => {
            state.branch_list = action.payload;
        },
    }
})

export const { setLoader, setStatementList, apiResponse, setPage, setBranchID, setBranchList } = cashVoucherStatementSlice.actions;
export default cashVoucherStatementSlice.reducer