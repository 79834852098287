import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";
import { reset } from "../expense/dashboardSlice";

export const initialState = {
    loader: false,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    total_rows: 0,
    showAddExpenseModal: false,
    showAddBalanceModal: false,
    cashVoucherData: [],
    payment_mode_list: null,
    branch_list: [],
    transaction_type_list: [],
    filters: { count: countPerPage, page: 1, voucher_id: "", from_date: 0, to_date: 0, transaction_type_id: 0, payment_mode_id: 0, branch_id: 0 },
    voucherBalance: { balanceLeft: 0, currentMonthExpense: 0, LastMonthExpense: 0 },
    cashID: 0,
    debitId: 0,
    creditId: 0,
    documentFieldShow: false,
    expenseData: null,
    showUploadModal: { show: false, id: '' },
    cancelledFlag: { show: false, id: '' },
    cashVoucherView: {},
    metricRender: false,
    currentWalletBalanceList: [],

}

export const cashVoucherDashBoardSlice = createSlice({
    name: 'cashVoucherDashboard',
    initialState,
    reducers: {
        loader: (state, action) => {
            state.loader = action.payload
        },
        paymentTypeList: (state, action) => {
            state.payment_mode_list = action.payload
        },
        showBalanceModal: (state, action) => {
            state.showAddBalanceModal = action.payload
        },
        showExpenseModal: (state, action) => {
            state.showAddExpenseModal = action.payload
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        voucherList: (state, action) => {
            const { data, total_rows } = action.payload
            state.cashVoucherData = data;
            state.total_rows = total_rows;
        },
        branchList: (state, action) => {
            state.branch_list = action.payload
        },
        transactionTypeList: (state, action) => {
            state.transaction_type_list = action.payload
        },
        pageChange: (state, action) => {
            state.filters = action.payload
        },
        filterChange: (state, action) => {
            state.filters = action.payload
        },
        voucherBalanceChange: (state, action) => {
            state.voucherBalance = action.payload
        },
        setCashId: (state, action) => {
            state.cashID = action.payload
        },
        setTransactionId: (state, action) => {
            const { debitId, creditId } = action.payload;
            state.debitId = debitId;
            state.creditId = creditId;
        },
        setDocumentFieldShow: (state, action) => {
            state.documentFieldShow = action.payload
        },
        setExpenseData: (state, action) => {
            state.expenseData = action.payload
        },
        setShowUploadModal: (state, action) => {
            state.showUploadModal = action.payload
        },
        setCancelledFlag: (state, action) => {
            state.cancelledFlag = action.payload
        },
        setCashVoucherView: (state, action) => {
            state.cashVoucherView = action.payload
        },
        setMetricRender: (state, action) => {
            state.metricRender = action.payload
        },
        setCurrentWalletBalanceList: (state, action) => {
            state.currentWalletBalanceList = action.payload
        },
        resetState: () => initialState,


    }
})

export const { paymentTypeList, showBalanceModal, showExpenseModal, branchList, apiResponse, voucherList, setCurrentWalletBalanceList,
    transactionTypeList, loader, pageChange, filterChange, voucherBalanceChange, setCashId, setTransactionId,
    setDocumentFieldShow, setExpenseData, setShowUploadModal, setCancelledFlag, setCashVoucherView, setMetricRender, resetState } = cashVoucherDashBoardSlice.actions;
export default cashVoucherDashBoardSlice.reducer