import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";

const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    addRegularisationModal: false,
    statusPendingId: 0,
    branchId: 0,
    employee_list: [],
    branch_list: [],
    attendance_status_list: [],
    filters: { count: countPerPage, page: 1, user: 0, status: 0 },
    regularisation_status_list: [],
    regularisationDataList: { total: 0, data: [] },
    currentTab: 'pending',
    employeeFilterList: [],
    attendanceLogList: { total: 0, data: [] },
    attendanceFilter: { count: countPerPage, page: 1, user: 0, from_date: 0, to_date: 0 },
}

export const regularisationModuleAdminList = createSlice({
    name: 'regularisationModuleAdminList',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setAddRegularisationModal: (state, action) => {
            state.addRegularisationModal = action.payload;
        },
        setStatusPendingId: (state, action) => {
            state.statusPendingId = action.payload;
        },
        setBranchId: (state, action) => {
            state.branchId = action.payload;
        },
        setEmployeeList: (state, action) => {
            state.employee_list = action.payload;
        },
        setBranchList: (state, action) => {
            state.branch_list = action.payload;
        },
        setAttendanceStatusList: (state, action) => {
            state.attendance_status_list = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setRegularisationStatuslist: (state, action) => {
            state.regularisation_status_list = action.payload;
        },
        setRegularisationData: (state, action) => {
            state.regularisationDataList = action.payload
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        setEmployeeFilterList: (state, action) => {
            state.employeeFilterList = action.payload;
        },
        setAttendanceLogList: (state, action) => {
            state.attendanceLogList = action.payload;
        },
        setAttendanceFilter: (state, action) => {
            state.attendanceFilter = action.payload;
        },
        resetState: () => initialState,
    }
})

export const { setAddRegularisationModal, setLoader, setRegularisationStatuslist, setAttendanceFilter, setAttendanceLogList, resetState, setEmployeeFilterList, setCurrentTab, setRegularisationData, apiResponse, setStatusPendingId, setBranchId, setFilters, setAttendanceStatusList, setEmployeeList, setBranchList } = regularisationModuleAdminList.actions;
export default regularisationModuleAdminList.reducer;