import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";

const initialState = {
  loader: true,
  serverResponseStatus: {
    apiCalled: false,
    message: "",
    hasError: false,
  },
  walletBalance: 0,
  metricsData: {
    expensePending: 0,
    expenseApproved: 0,
    expenseSettled: 0,
    expenseRejected: 0,
  },
  filters: {
    page: 1,
    count: countPerPage,
    expense_id: "",
    from_date: 0,
    to_date: 0,
    category_id: 0,
    status_id: 0,
    is_draft: false,
  },
  expenseList: [],
  total_rows: 0,
  category_list: [],
  status_list: [],
  pendingId: 0,
};

export const expenseEmployeeDashboardSlice = createSlice({
  name: "expenseEmployeeDashboard",
  initialState,
  reducers: {
    setServerResponse: (state, action) => {
      state.serverResponseStatus = { ...action.payload };
    },
    resetServerResponse(state) {
      state.serverResponseStatus = {
        apiCalled: false,
        message: "",
        hasError: false,
      };
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setWalletBalance: (state, action) => {
      state.walletBalance = action.payload;
    },
    setMetricsData: (state, action) => {
      state.metricsData = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setExpenseList: (state, action) => {
      const { data, total } = action.payload;
      state.expenseList = data;
      state.total_rows = total;
    },
    setCategoryList: (state, action) => {
      state.category_list = action.payload;
    },
    setStatusList: (state, action) => {
      state.status_list = action.payload;
    },
    setPage: (state, action) => {
      state.filters = action.payload;
    },
    setPendingId: (state, action) => {
      state.pendingId = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setExpenseList,
  setFilters,
  setLoader,
  setCategoryList,
  setStatusList,
  setMetricsData,
  reset,
  setWalletBalance,
  setPendingId,
  setServerResponse,
  resetServerResponse,
  setPage,
} = expenseEmployeeDashboardSlice.actions;
export default expenseEmployeeDashboardSlice.reducer;
