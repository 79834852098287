import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    leaveCategoryList: [],
    leavePolicyList: [],
}
export const leavePolicyDashboard = createSlice({
    name: 'LeavePolicyDahboard',
    initialState,
    reducers: {
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setLoadeer: (state, action) => {
            state.loader = action.payload
        },
        setLeaveCategoryList: (state, action) => {
            state.leaveCategoryList = action.payload;
        },
        setLeavePolicyList: (state, action) => {
            state.leavePolicyList = action.payload;
        },
        resetState: () => initialState,
    }
})

export const { apiResponse, setLoadeer, setLeaveCategoryList, setLeavePolicyList,resetState } = leavePolicyDashboard.actions;
export default leavePolicyDashboard.reducer;