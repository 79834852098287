import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    weeklyPolicyList: [],
    deleteConfirmationModal: { show: false, data: "" },

}

export const weeklyoffPolicyList = createSlice({
    name: 'weeklyoffPolicyList',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setWeeklyPolicyList: (state, action) => {
            state.weeklyPolicyList = action.payload;
        },
        setDeleteConfirmationModal: (state, action) => {
            state.deleteConfirmationModal = action.payload;
        },
        resetState: () => initialState,
    }
})

export const { setLoader, apiResponse, setWeeklyPolicyList, setDeleteConfirmationModal, resetState } = weeklyoffPolicyList.actions
export default weeklyoffPolicyList.reducer;