import { createSlice } from "@reduxjs/toolkit";
import { countPerPage } from "src/config/app";

const initialState = {
    loader: true,
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false,
    },
    regularisationData: {},
    actionModal: { show: false, action: '', title: "" },
    cancelModal: false,
}

export const regularisationView = createSlice({
    name: 'regularisationView',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        apiResponse: (state, action) => {
            state.serverResponseStatus = { ...action.payload }
        },
        setRegularisationData: (state, action) => {
            state.regularisationData = action.payload;
        },
        setActionModal: (state, action) => {
            state.actionModal = action.payload;
        },
        setCancelModal: (state, action) => {
            state.cancelModal = action.payload
        },
        resetState: () => initialState,
    }
})

export const { setLoader, apiResponse, setRegularisationData, setActionModal, setCancelModal, resetState } = regularisationView.actions;
export default regularisationView.reducer; 